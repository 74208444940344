<template>
  <div>
    <b-card class="mb-1">
      <b-card-header class="m-0 p-0">
        <div class="card-title">
          {{ $t('weightsSettings.header') }}
        </div>
        <b-button v-b-toggle.collapse-3 variant="text-primary">
          <span class="when-open">{{ $t('weightsSettings.descriptionButton.hide') }}</span>
          <span class="when-closed">{{ $t('weightsSettings.descriptionButton.show') }}</span>
          {{ $t('weightsSettings.descriptionButton.description') }}
          <span class="when-open"><feather-icon icon="ChevronUpIcon" /></span>
          <span class="when-closed"><feather-icon icon="ChevronDownIcon" /></span>
        </b-button>
      </b-card-header>
      <b-collapse id="collapse-3" :visible="getShowDescription">
        <div ref="container">
          <div class="text-left w-100">
            <vs-row class="mb-1">
              {{ $t('weightsSettings.description') }}
            </vs-row>
            <vs-row class="mb-1">
              <b> {{
                $t('weightsSettings.tableHeaders.fullMatchField')
              }}</b> - {{ $t('weightsSettings.strategyDescription.full') }}
            </vs-row>
          </div>
        </div>
      </b-collapse>
    </b-card>
    <b-card :title="$t('weightsSettings.headerList')">
      <div ref="container">
        <vs-row class="mb-1">
          {{ $t('weightsSettings.shortDescription') }}
        </vs-row>
        <div class="mt-2 text-left w-100">
          <vs-row>
            <vs-table class="stretchTable viewTable" :data="getWeights">
              <template slot="thead">
                <vs-th v-for="(name, columnIndex) in columnNamesView" :key="columnIndex">
                  <span>{{ $t('weightsSettings.tableHeaders.' + name) }}</span>
                </vs-th>
              </template>
              <vs-tr v-for="(item,key) in getWeights" :key="key">
                <vs-td>
                  {{ getFieldTranslation(item.field) }}
                </vs-td>
                <vs-td />
                <vs-td>
                  <vs-switch v-model="item.fullMatch" class="inline-flex" @click="saveSettings">
                    <template v-slot:on>
                      {{ $t('search.settings.enabled_on') }}
                    </template>
                    <template v-slot:off>
                      {{ $t('search.settings.enabled_off') }}
                    </template>
                  </vs-switch>
                </vs-td>
                <vs-td>
                  <span>
                    <span class="weightBlock">{{ item.weight }}</span>
                    <vs-input
                      type="range"
                      min="0"
                      max="10" :value="item.weight" class="inputRange"
                      @change="updateRowWeight($event.target.value, key)"
                    />
                  </span>
                </vs-td>
                <vs-td>

                  <a class="d-b-inline" href="#" @click.prevent="removeRowWeight(key)">
                    <vs-tooltip :text="$t('facetedSearchSettings.deleteTooltip')">
                      <trash-2-icon class="text-danger" />
                    </vs-tooltip>
                  </a>

                </vs-td>
              </vs-tr>
            </vs-table>
          </vs-row>
        </div>
      </div>
    </b-card>

    <!--    Dodaj nowe pole-->
    <b-card :title="$t('weightsSettings.headerAdd')" :hidden="!getAvailableFields.length">
      <div ref="container">
        <div class="mt-2 text-left w-100">
          <vs-row class="mt-3">
            <vs-table class="stretchTableOne bg-gray addTable" :data="[1]">
              <template slot="thead">
                <vs-th v-for="(name, columnIndex) in columnNamesAdd" :key="columnIndex">
                  <span>{{ $t('weightsSettings.tableHeaders.' + name) }}</span>
                </vs-th>
              </template>
              <vs-tr>
                <vs-td>
                  <vs-select
                    class="max-width"
                    placeholder="Wybierz pole"
                    @input="updateSelectedField($event)"
                  >
                    <vs-select-item
                      v-for="(element) in getAvailableFields"
                      :key="element.value"
                      :text="element.label"
                      :value="element.value"
                    />
                  </vs-select>
                </vs-td>
                <vs-td>
                  <vs-input
                    class="max-width"
                    type="number"
                    :value="addValue"
                    min="0"
                    max="10"
                    @input="updateSelectedWeight($event)"
                  />
                </vs-td>
                <vs-td>
                  <vs-button class="" @click="addRowWeight()">
                    {{ $t('weightsSettings.add') }}
                  </vs-button>
                </vs-td>
              </vs-tr>
            </vs-table>
          </vs-row>

        </div>
      </div>
    </b-card>
    <b-card :hidden="!!getAvailableFields.length">
      <b-alert show variant="light">
        {{ $t('weightsSettings.allFieldsSelected') }}
      </b-alert>
    </b-card>

  </div>
</template>
<script>
import store from '@/store'
import { Trash2Icon } from 'vue-feather-icons'
import SearchInstanceStore from '@/store/search/searchInstance'
import { mapGetters } from 'vuex'

export default {
  name: 'WeightsSettings',
  components: { Trash2Icon },
  props: {
    weights: {
      type: Object,
      default() {
        return {
          availableWeightsSelected: 'title',
        }
      },
    },
  },
  data() {
    return {
      validated: false,
      newValue: null,
      availableFields: [],
      addValue: 1,
      columnNamesAdd: ['field', 'weight', 'actionAdd'],
      columnNamesView: ['field', 'empty', 'fullMatchField', 'weight', 'actionRemove'],
    }
  },
  computed: {
    getAvailableFields() {
      let { availableFields, weights: selectedOptions } = store.state.searchWeight

      const availableToSelectOptions = availableFields.filter(option => !selectedOptions.some(selectedOption => selectedOption.field === option))

      availableFields = availableToSelectOptions.map(item => ({
        value: item,
        label: this.getFieldTranslation(item),
      }))

      this.availableFields = availableFields
      return this.availableFields
    },
    getWeights() {
      return store.state.searchWeight.weights
    },

    ...mapGetters('searchWeight', ['getShowDescription']),

    getSearchId() {
      return SearchInstanceStore.state.searchInstanceId
    },
  },
  watch: {
    getSearchId() {
      this.getData()
    },
  },
  mounted() {
    if (this.getSearchId !== '') {
      this.getData()
    }
  },
  methods: {
    setDescription(data) {
      store.dispatch('searchWeight/setShowDescription', data)
    },
    saveSettings() {
      store.dispatch('searchWeight/storeWeights', this.getSearchId)
    },
    getFieldTranslation(field) {
      return this.$te(`weightsSettings.fields.${field}`) ? this.$t(`weightsSettings.fields.${field}`) : field
    },
    getData() {
      store.dispatch('searchWeight/getData', this.getSearchId)
      this.weights.items = store.state.searchWeight.weights
    },
    updateSelectedField(value) {
      this.weights.availableWeightsSelected = value
    },
    updateSelectedWeight(value) {
      let parmV = value
      if (parmV > 10) {
        parmV = 10
      }
      if (parmV < 0) {
        parmV = 0
      }
      this.addValue = parmV
    },
    updateRowWeight(value, key) {
      store.dispatch('searchWeight/setWeightKey', {
        key,
        value,
      })
    },

    removeRowWeight(key) {
      store.dispatch('searchWeight/removeWeight', {
        key,
      })
    },
    addRowWeight(e) {
      let isset = false
      const selectedField = this.weights.availableWeightsSelected

      this.weights.items.forEach(v => {
        if (v.field === selectedField) {
          isset = true
        }
      })
      if (isset) {
        return
      }
      store.dispatch('searchWeight/add', {
        field: selectedField,
        weight: this.addValue,
        fullMatch: false,
        match: true,
        exact: false,
      })
    },
  },
}
</script>
<style scoped>
.weightBlock {
  display: inline-block;
  float: left;
  background-color: #fbfbfb;
  height: 32px;
  width: 32px;
  border: 1px solid #d7d2d2;
  margin-right: 10px;
  text-align: center;
  padding-top: 7px;
  border-radius: 4px;
}

.stretchTableOne {
  width: 100%;
}

.stretchTableOne td {
  width: 50%;
}

.stretchTable {
  width: 100%;
}

.stretchTable td {
  width: 40%;
}

.stretchTable td:first-child {
  width: 60%;
}

.stretchTable td:last-child {
  width: 1px;
}

.inputRange {
  -webkit-appearance: none; /* Hides the slider so that custom slider can be made */
  width: 100%; /* Specific width is required for Firefox. */
  background: transparent; /* Otherwise white in Chrome */
  border: none;
}

.inputRange:focus {
  border: none;
}

.addTable * {
  background-color: #f5f5f5;
}

.addTable {
  padding: 5px;
  background-color: #f5f5f5;
  border-radius: 8px;
  margin-bottom: 20px;
}

.viewTable td:nth-child(1) {
  width: 150px;
}

.viewTable td:nth-child(2) {
  width: 10px;
  text-align: right;
  color: red;
}

.viewTable td:nth-child(3), .viewTable td:nth-child(4), .viewTable td:nth-child(5) {
  width: 32px;
}

.addTable .max-width {
  width: 100%;
}
</style>
<style>
@media screen and (-webkit-min-device-pixel-ratio: 0) {
  input[type='range'] {

    overflow: hidden;
    width: 100%;
    -webkit-appearance: none;
    background-color: rgba(0, 0, 0, 0);
  }

  input[type='range']::-webkit-slider-runnable-track {
    height: 10px;
    -webkit-appearance: none;
    color: #13bba4;
    margin-top: -1px;
  }

  input[type='range']::-webkit-slider-thumb {
    margin-top: -5px;
    width: 20px;
    -webkit-appearance: none;
    height: 20px;
    cursor: ew-resize;
    background: #6aeac9;
    box-shadow: -900px 0 0 900px #39ba9b;
  }

  .validationRow td {
    text-align: center;
    color: red;
  }

}

.collapsed > .when-open,
.not-collapsed > .when-closed {
  display: none;
}

</style>
